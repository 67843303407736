import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { SecondaryButton } from '../components/Buttons/SecondaryButton';
import Layout from '../components/common/Layout';
import { MetaData } from '../components/common/meta';
import { FinancialToolsDesktop } from '../components/FinancialToolsDesktop/FinancialToolsDesktop';
import Hero from '../components/HomePage/Heros/B/Hero';
import { IPostCard, PostCard } from '../components/Post/Card';
import { PostGrid } from '../components/Post/Grid';
import { TagPosts } from '../components/Post/Tag';
import { TrendingPosts } from '../components/Post/Trending';
import DebtIcon from '../images/Debt';
import EarnMoneyIcon from '../images/EarnMoney';
import SaveMoneyIcon from '../images/SaveMoney';
import TrendingIcon from '../images/Trending';

import style from '../styles/pages/index.scss';

import cn from 'classnames';
import { graphql, Link } from 'gatsby';

interface IIndexProps {
  data: {
    allGhostPost: {
      edges: {
        node: IPostCard;
      }[];
      totalCount: number;
    };
    allGhostPostFeatured: any;
    allGhostPageFeatured: any;
    allGhostPostEarnMoney: any;
    allGhostPageEarnMoney: any;
    allGhostPostSaveMoney: any;
    allGhostPageSaveMoney: any;
    allGhostPostDebt: any;
    allGhostPageDebt: any;
    allGhostPage: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */

const Index = ({ data, location }: IIndexProps) => {
  const {
    allGhostPost,
    allGhostPage,
    allGhostPostFeatured,
    allGhostPageFeatured,
    allGhostPostEarnMoney,
    allGhostPageEarnMoney,
    allGhostPostSaveMoney,
    allGhostPageSaveMoney,
    allGhostPostDebt,
    allGhostPageDebt,
  } = data;

  const allPostsAndPages = allGhostPost.edges.concat(allGhostPage.edges);

  const allPostsAndPagesFeatured = allGhostPostFeatured.edges.concat(allGhostPageFeatured.edges);

  const allPostsAndPagesEarnMoney = allGhostPostEarnMoney.edges.concat(allGhostPageEarnMoney.edges);
  const allPostsAndPagesSaveMoney = allGhostPostSaveMoney.edges.concat(allGhostPageSaveMoney.edges);
  const allPostsAndPagesDebt = allGhostPostDebt.edges.concat(allGhostPageDebt.edges);

  const handleReadMoreEarnMoney = () => {
    window.location.href = '/earn-money/';
  };

  const handleReadMoreSaveMoney = () => {
    window.location.href = '/save-money/';
  };

  const handleReadMoreDebt = () => {
    window.location.href = '/debt/';
  };

  const handleReadMorePosts = () => {
    window.location.href = '/posts/';
  };

  return (
    <>
      <MetaData
        location={location}
        data={{}}
        title="The Latest Personal Finance Content from Think Save Retire"
        description="Read the latest personal finance information on Think Save Retire including stories of reaching financial goals such as retiring by 40 or building wealth."
      />
      <Hero />
      <Layout isLightTheme mainClass={style.main}>
        <div className={style.mainPosts}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <h6 className={style.featureTitle}>Featured Article</h6>
                <PostCard
                  post={allPostsAndPagesFeatured[0].node}
                  className={style.featuredPost}
                  isExcerptShown
                />
              </div>
              <div className="col-12 col-lg-4 mt-4 mt-lg-0">
                <h2 className={style.heading}>
                  <Link to="/trending/">
                    <TrendingIcon /> Trending Posts
                  </Link>
                </h2>
                <TrendingPosts posts={allPostsAndPagesFeatured.slice(1, 5)} />
              </div>
            </div>
          </div>
        </div>
        <SignUpBanner className="d-none d-lg-block" />
        <div className={style.tagsPosts}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <h2 className={style.heading}>
                  <Link to="/earn-money/">
                    <EarnMoneyIcon /> Earn Money
                  </Link>
                </h2>
                <PostCard
                  post={allPostsAndPagesEarnMoney[0].node}
                  className={style.featuredTagPost}
                />
                <TagPosts posts={allPostsAndPagesEarnMoney.slice(1, 4)} />
                <SecondaryButton className={style.readMore} onClick={handleReadMoreEarnMoney}>
                  Read more from Earn Money
                </SecondaryButton>
              </div>
              <div className="col-12 col-lg-4 mt-4 mt-md-0">
                <h2 className={style.heading}>
                  <Link to="/save-money/">
                    <SaveMoneyIcon /> Save Money
                  </Link>
                </h2>
                <PostCard
                  post={allPostsAndPagesSaveMoney[0].node}
                  className={style.featuredTagPost}
                />
                <TagPosts posts={allPostsAndPagesSaveMoney.slice(1, 4)} />
                <SecondaryButton className={style.readMore} onClick={handleReadMoreSaveMoney}>
                  Read more from Save Money
                </SecondaryButton>
              </div>
              <div className="col-12 col-lg-4 mt-4 mt-md-0">
                <h2 className={style.heading}>
                  <Link to="/debt/">
                    <DebtIcon /> Debt
                  </Link>
                </h2>
                <PostCard post={allPostsAndPagesDebt[0].node} className={style.featuredTagPost} />
                <TagPosts posts={allPostsAndPagesDebt.slice(1, 4)} />
                <SecondaryButton className={style.readMore} onClick={handleReadMoreDebt}>
                  Read more from Debt
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
        <SignUpBanner className={cn(style.signUpBanner, 'd-lg-none')} />
        <div className={style.posts}>
          <div className="container">
            <PostGrid className={style.allPosts} posts={allPostsAndPages.slice(0, 6)} />
            <SecondaryButton className={style.readMore} onClick={handleReadMorePosts}>
              Load more stories
            </SecondaryButton>
          </div>
        </div>
        <FinancialToolsDesktop />
      </Layout>
    </>
  );
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery {
    allGhostPost(sort: { order: DESC, fields: [published_at, featured] }, limit: 10) {
      edges {
        node {
          slug
          feature_image
          excerpt
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
          tags {
            name
            slug
          }
          html
        }
      }
      totalCount
    }
    allGhostPage(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: {
        authors: { elemMatch: { name: { ne: "Robot" } } }
        tags: { elemMatch: { name: { ne: "#noindex" } } }
      }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          excerpt
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
          tags {
            name
            slug
          }
          html
        }
      }
      totalCount
    }
    allGhostPostFeatured: allGhostPost(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: { featured: { eq: true } }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          featured
          excerpt
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
          html
        }
      }
    }
    allGhostPageFeatured: allGhostPage(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: { featured: { eq: true } }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          featured
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
          html
        }
      }
    }
    allGhostPostEarnMoney: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: {
              in: [
                "side-hustle"
                "generate-income"
                "digital-nomad-life"
                "millennial-money"
                "build-wealth"
              ]
            }
          }
        }
      }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          tags {
            name
            slug
          }
        }
      }
    }
    allGhostPostSaveMoney: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { in: ["money-saving-habits", "financial-tools", "budget"] } } }
      }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          tags {
            name
            slug
          }
        }
      }
    }
    allGhostPostDebt: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: {
              in: [
                "getting-out-of-debt"
                "opportunities-to-save"
                "financial-literacy"
                "tackling-debt"
                "extra-cash"
                "credit-cards"
              ]
            }
          }
        }
      }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          tags {
            name
            slug
          }
        }
      }
    }
    allGhostPageEarnMoney: allGhostPage(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: { tags: { elemMatch: { slug: { eq: "side-hustle" } } } }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          featured
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
        }
      }
    }
    allGhostPageSaveMoney: allGhostPage(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: { tags: { elemMatch: { slug: { eq: "money-saving-habits" } } } }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          featured
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
        }
      }
    }
    allGhostPageDebt: allGhostPage(
      sort: { order: DESC, fields: [published_at, featured] }
      filter: { tags: { elemMatch: { slug: { eq: "getting-out-of-debt" } } } }
      limit: 10
    ) {
      edges {
        node {
          slug
          feature_image
          featured
          title
          published_at
          primary_author {
            name
            profile_image
          }
          slug
        }
      }
    }
  }
`;
