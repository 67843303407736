import React from 'react';

import TypedJS, { TypedOptions } from 'typed.js';

interface TypedProps extends TypedOptions {
  className?: string;
  strings: string[];
}

export const Typed = (options: TypedProps) => {
  const { className, strings, ...restOptions } = options;
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new TypedJS(el.current, { strings, ...restOptions });

    return () => {
      typed.destroy();
    };
  }, [strings, restOptions]);

  return (
    <div className={className}>
      <span ref={el} />
    </div>
  );
};
